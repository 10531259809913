<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <label class="label">Nome do layout</label>
        <v-text-field
          v-model="layout.name"
          class="mt-2"
          label="Informe do layout"
          maxlength="70"
          solo
          :rules="layout.name ? [] : [rule.required]"
          :disabled="isDisableHeader"
        />
      </v-col>
      <!-- <v-col v-if="isFinancialGroup" cols="12" sm="6" md="3">
        <label class="label">Grupo Financeiro</label>
        <v-autocomplete
          class="mt-2"
          :items="financialGroups"
          v-model="layout.financialGroupId"
          :loading="loadindFinancialGroups"
          item-text="name"
          item-value="id"
          item-color="textPrimary"
          placeholder="Selecione o grupo financeiro"
          color="textPrimary"
          @input="actionsChangeFinancialGroup()"
          :disabled="isDisableHeader"
          solo
        />
      </v-col> -->
      <!-- <v-col v-if="isInsuranceCarrier" cols="12" sm="6" md="3">
        <label class="label">Operadora</label>
        <v-autocomplete
          class="mt-2"
          :items="insuranceCarriers"
          v-model="layout.insuranceCarrierId"
          item-text="xipp_commercial_name"
          item-value="id"
          item-color="textPrimary"
          placeholder="Selecione o operadora"
          color="textPrimary"
          :disabled="isDisableHeader"
          solo
          @input="emitHeaderConfigurationEvent(); emitLayoutInfoEvent()"
        />
      </v-col>
      <v-col v-if="isFinancialGroup" cols="12" sm="6" md="3">
        <label class="label">Contrato</label>
        <v-autocomplete
          class="mt-2"
          :loading="loadingContracts"
          :items="contracts"
          v-model="layout.contractId"
          @input="getSubContractsByContractId(layout.contractId); emitLayoutTypeEvent(); layout.subContractId = null"
          item-text="text"
          item-value="id"
          item-color="textPrimary"
          placeholder="Não informado"
          color="textPrimary"
          solo
          :disabled="isDisableHeader"
        />
      </v-col>
      <v-col v-if="isFinancialGroup" cols="12" sm="6" md="3">
        <label class="label">Sub-Contrato</label>
        <v-autocomplete
          class="mt-2"
          :loading="loadingSubContracts"
          :items="subContracts"
          v-model="layout.subContractId"
          @input="emitLayoutTypeEvent();"
          item-text="text"
          item-value="id"
          item-color="textPrimary"
          placeholder="Não informado"
          color="textPrimary"
          solo
          :disabled="isDisableHeader"
        />
      </v-col> -->
      <v-col v-if="isInsuranceCarrier" cols="12" sm="6" md="3">
        <label class="label">Tipo de Interface</label>
        <v-autocomplete
          class="mt-2"
          :items="typeInterfaces"
          :loading="loadTypeInterfaces"
          item-text="name"
          item-color="textPrimary"
          label="Informe o tipo de interface"
          color="textPrimary"
          solo
          :disabled="isDisableHeader"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <label class="label">Tipo de Integração*</label>
        <v-autocomplete
          class="mt-2"
          :items="typeIntegrations"
          v-model="layout.typeIntegrationValue"
          item-text="text"
          item-value="value"
          item-color="textPrimary"
          label="Informe o tipo de integração"
          color="textPrimary"
          :rules="[rule.required]"
          validate-on-blur
          clearable
          solo
          :disabled="isDisableHeader"
        />
      </v-col>
      <!-- <v-col cols="12" sm="6" md="3">
        <label class="label">Coletor</label>
        <v-autocomplete
          class="mt-2"
          v-model="layout.collectorIntegrationConfigId"
          :items="collectors"
          :loading="loadingCollectors"
          item-text="text"
          item-value="id"
          item-color="textPrimary"
          label="Informe o coletor"
          color="textPrimary"
          clearable
          :rules="layout.typeIntegrationValue && (layout.typeIntegrationValue === 'RECEIVEMENT' || layout.typeIntegrationValue === 'PAYROLL') ? [rule.required] : []"
          solo
          :disabled="isDisableHeader"
          validate-on-blur
        />
      </v-col> -->
      <v-col cols="12" sm="6" md="3">
        <label class="label">Tipo do Arquivo</label>
        <v-text-field
          label="Tipo do Arquivo"
          :value="layout.fileType === 'XLS' ? 'XLSX' : layout.fileType"
          solo
          class="mt-2"
          readonly
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <label class="label">Tipo do Layout</label>
        <v-text-field
          class="mt-2"
          label="Tipo do Layout"
          :value="layout.layoutType === 'NONE' ? 'Nenhum' : layout.layoutType === 'POSITIONAL' ? 'Posicional' : layout.layoutType === 'DELIMITED' ? 'Delimitado' : null"
          solo
          readonly
          disabled
        />
      </v-col>
      <v-col v-if="layout.fileType !== 'XLS'" cols="12" sm="6" md="3">
        <label class="label">Delimitador</label>
        <v-text-field
          class="mt-2"
          label="Delimitador"
          :value="layout.delimiter"
          solo
          readonly
          disabled
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Rules from '@/shared/validators/formRules';
import CollectorIntegrationService from '@/services-http/sdi/CollectorIntegrationService';
import CarrierInterfaceService from '@/services-http/odoo/CarrierInterfaceService';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import { mapGetters, mapMutations } from 'vuex';

export default ({
  data: () => ({
    financialGroups: [],
    contracts: [],
    subContracts: [],
    insuranceCarriers: [],
    typeInterfaces: [],
    collectors: [],

    layoutType: {},
    layout: {},

    isEdit: false,
    isCopy: false,
    isFinancialGroup: false,
    isInsuranceCarrier: false,
    isLoadingContract: false,
    isLoadingSubContract: false,

    loadindFinancialGroups: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadTypeInterfaces: false,
    loadingCollectors: false,
    isDisableHeader: false,

    typeIntegrations: [
      { text: 'Recebimento', value: 'RECEIVEMENT' },
      { text: 'Envio', value: 'SHIPPING' },
      { text: 'Folha', value: 'PAYROLL' },
    ],
  }),

  watch: {
    layout: {
      deep: true,
      handler(val) {
        if (val) {
          this.$emit('headerConfigurationComponent', val);
        }
      },
    },
    layoutData: {
      deep: true,
      handler(newValue, oldValue) {
        if (Object.keys(oldValue).length === 0) {
          this.loadTypeInterface();
        }
      },
    },
  },

  mixins: [
    InsuranceCarriersMixin,
    FinancialGroupsMixin,
    ContractsMixin,
  ],

  async mounted() {
    this.routeSpotter();
    if(Object.keys(this.layoutData).length > 0) {
      await this.loadSessionStorage();
    }
    if (this.layout.insuranceCarrierId) {
      this.loadTypeInterface();
    }
  },

  methods: {
    ...mapMutations({
      setLayoutType: 'layout-import/setLayoutType',
      setLayout: 'layout-import/setLayout',
    }),
    async loadSessionStorage() {
      // this.layoutType = JSON.parse(sessionStorage.getItem('layoutType'));
      // this.layout = JSON.parse(sessionStorage.getItem('layout'));
      this.layoutType = JSON.parse(JSON.stringify(this.layoutTypeData));
      this.layout = JSON.parse(JSON.stringify(this.layoutData));
      this.financialGroups = this.layoutType.financialGroups;
      this.contracts = this.layoutType.contracts;
      this.subContracts = this.layoutType.subContracts;
      this.insuranceCarriers = this.layoutType.insuranceCarriers;
      this.layout.financialGroupId = this.layoutType.financialGroupId;
      this.layout.insuranceCarrierId = this.layoutType.insuranceCarrierId;
      this.layout.entityType = this.layoutEntityType;
      // this.requestCollectors();

      if (this.layout && this.layout.financialGroupId) {
        this.isFinancialGroup = true;
        if (this.rule.isEmptyArray(this.financialGroups)) await this.getFinancialGroups();
        if (this.rule.isEmptyArray(this.contracts)) await this.getContractsByFinancialGroupId(this.layout.financialGroupId);
        if (this.rule.isEmptyArray(this.subContracts)) await this.getSubContractsByContractId(this.layout.contractId);
        if (!this.isDisableHeader) {
          this.emitHeaderConfigurationEvent();
          this.emitLayoutInfoEvent();
        }
      } else {
        this.isInsuranceCarrier = true;
        if (this.rule.isEmptyArray(this.insuranceCarriers)) await this.getInsuranceCarriers();
      }
    },
    requestCollectors(type) {
      if (type === 'change') {
        this.layout.collectorIntegrationConfigId = null;
      }

      this.loadingCollectors = true;
      const queryParams = this.layout.financialGroupId ? `financialGroupId=${this.layout.financialGroupId}` : `insuranceCarrierId=${this.layout.insuranceCarrierId}`;
      let typeIntegrationQueryParam = '';

      if (this.layout.typeIntegrationValue === 'RECEIVEMENT' || this.layout.typeIntegrationValue === 'SHIPPING') {
        typeIntegrationQueryParam = '&type=FTP';
      } else if (this.layout.typeIntegrationValue === 'PAYROLL') {
        typeIntegrationQueryParam = '&type=PAYROLL';
      }

      const queryString = `?${queryParams}${typeIntegrationQueryParam}`;

      this.collectorIntegrationService.FindAllByFilters(queryString).then((response) => {
        this.collectors = response.data.content;
        this.collectors = this.collectors.map((element) => ({
          text: `${element.type}/${element.name}`,
          id: element.id,
        }));
        this.layoutType.collectors = this.collectors;
        this.loadingCollectors = false;
      }).catch(() => {
        this.loadingCollectors = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao carregar os coletores');
      });
    },
    loadTypeInterface() {
      this.loadTypeInterfaces = true;
      this.carrierInterfaceService.FindAllByCarrierId(this.layout.insuranceCarrierId).then((response) => {
        if (response && response.data.records && response.data.records.length > 0) {
          this.typeInterfaces = response.data.records;
        }
        this.loadTypeInterfaces = false;
      }).catch(() => {
        this.loadTypeInterfaces = false;
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao carregar os tipos de interfaces');
      });
    },
    routeSpotter() {
      if (this.$route.name === 'LayoutImportColumnEditConfigs' || this.$route.name === 'LayoutImportTransformationEditConfigs') {
        this.isEdit = true;
      } else if (this.$route.name === 'LayoutImportColumnCopyConfigs' || this.$route.name === 'LayoutImportTransformationCopyConfigs') {
        this.isCopy = true;
      }
      this.$emit('isEditControll', this.isEdit);
      this.$emit('isCopyControll', this.isCopy);

      if (this.$route.name === 'LayoutImportTransformationConfigs' || this.$route.name === 'LayoutImportTransformationEditConfigs' || this.$route.name === 'LayoutImportTransformationCopyConfigs') {
        this.isDisableHeader = true;
      }
    },
    emitLayoutTypeEvent() {
      if (!this.rule.isEmptyArray(this.financialGroups) || !this.rule.isEmptyArray(this.contracts) || !this.rule.isEmptyArray(this.subContracts)) {
        this.emitHeaderConfigurationEvent();
        this.emitLayoutInfoEvent();
      }
    },
    emitHeaderConfigurationEvent() {
      this.layout.financialGroupName = this.getFinancialGroupNameById(this.layout.financialGroupId);
      this.layout.contractName = this.getContractNameById(this.layout.contractId);
      this.layout.subContractName = this.getSubContractNameById(this.layout.subContractId);
      this.layout.insuranceCarrierName = this.getInsuranceCarrierNameById(this.layout.insuranceCarrierId);
      this.$emit('headerConfigurationComponent', this.layout);
    },
    emitLayoutInfoEvent() {
      this.layoutType.contracts = this.contracts;
      this.layoutType.subContracts = this.subContracts;
      this.layoutType.financialGroupId = this.layout.financialGroupId;
      this.layoutType.contractId = this.layout.contractId;
      this.layoutType.subContractId = this.layout.subContractId;
      this.layoutType.insuranceCarrierId = this.layout.insuranceCarrierId;
      this.$emit('setLayoutInfoInLayoutType', this.layoutType);
    },
    actionsChangeFinancialGroup() {
      this.layout.contractId = null;
      this.layout.subContractId = null;
      this.layout.collectorIntegrationConfigId = null;
      this.getContractsByFinancialGroupId(this.layout.financialGroupId);
      this.emitLayoutTypeEvent();
      // this.requestCollectors();
    },
  },

  computed: {
    ...mapGetters({
      layoutData: 'layout-import/layout',
      layoutTypeData: 'layout-import/layoutType',
      layoutEntityType: 'layout-import/layoutEntityType',
    })
  },

  created() {
    this.rule = new Rules();
    this.collectorIntegrationService = new CollectorIntegrationService();
    this.carrierInterfaceService = new CarrierInterfaceService();
  },
});
</script>
